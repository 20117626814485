.dropdown:focus-within .dropdown-menu {
    opacity:1;
    transform: translate(0) scale(1);
    visibility: visible;
}


.open {
    opacity:1;
    transform: translate(0) scale(1);
    visibility: visible;
}