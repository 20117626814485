.check-primary:checked + div {
    @apply border-8;
    @apply border-indigo-600;
}

.check-secondary:checked + div {
    @apply border-gray-600;
}
.check-secondary:checked + div svg {
    @apply block;
}