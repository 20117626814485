/* Tailwind Theme Imports*/
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-y: scroll !important;
}
body::-webkit-scrollbar {
  width: 10px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgba(100,100,100,0.3);
}